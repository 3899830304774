/* src/Home.css */

.hero {
    display: flex;
    height: 60vh;
    position: relative;
}

.hero-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 2;
}

.hero-content h2 {
    font-size: 2em;
    z-index: 2;
    display: block;
    text-align: center;
}

.hero h2 {
    margin: 0;
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Updated for mobile */
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.hero-video video {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.hero-image {
    flex: 1;
    background-size: cover;
    background-position: center;
}

.welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, rgba(45,45,45,1) 0%, rgba(0,0,0,1) 100%);
    color: white;
    text-align: center;
    min-height: 200px;
    height: 20vh;
}

.welcome h2 {
    font-size: 2em;
}

.welcome-content {
    max-width: 800px;
    margin: 0 auto;
}

.learn-more-btn {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.learn-more-btn:hover {
    background-color: #444;
    transform: translateY(-2px);
}

.image-section {
    height: 40vh;
}

.image-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.quote-section {
    padding: 40px;
    background-color: #656553;
    text-align: center;
    font-size: 1.5em;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
}

.quote-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.quote-section p {
    margin-bottom: 10px;
    line-height: 1.5;
    position: relative;
    z-index: 2;
}

.quote-section span {
    font-size: 1em;
    font-style: italic;
    color: #ccc;
    font-weight: normal;
    position: relative;
    z-index: 2;
}

.new-section {
    padding: 40px;
    background-color: #fff;
    text-align: left;
}

.new-section-content {
    max-width: 50%;
    margin: 0 0 0 25%;
}

.new-section h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
}

.title-line-home {
    width: 50px;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.85);
    margin-bottom: 20px;
}

.new-section p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.lets-start-btn {
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.lets-start-btn:hover {
    background-color: #000;
    color: #fff;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .hero, .image-section, .new-section, .quote-section {
        flex-direction: column;
    }

    .hero-content, .welcome-content, .new-section-content {
        padding: 20px;
    }

    .hero-content h2, .welcome h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .new-section-content {
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .hero, .image-section, .new-section, .quote-section {
        flex-direction: column;
    }

    .hero-content, .welcome-content, .new-section-content {
        padding: 20px;
    }

    .hero-content h2, .welcome h2 {
        font-size: 1.5em;
    }

    .new-section-content {
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .hero-content h2, .welcome h2 {
        font-size: 1.2em;
    }

    .learn-more-btn, .lets-start-btn {
        font-size: 0.8em;
        padding: 8px 16px;
    }

    .quote-section {
        padding: 20px;
        font-size: 1.2em;
    }

    .quote-section span {
        font-size: 0.8em;
    }
}