#theKendrickLamarEP .ep-section {
    background-color: #5e5e49;
    color: white;
    padding: 20px; /* Reduced padding for mobile */
}

#theKendrickLamarEP .ep-content {
    max-width: 1000px;
    margin: 0 auto;
}

#theKendrickLamarEP .album-section {
    background-color: rgb(67, 67, 52);
    color: white;
    padding: 15px; /* Adjusted padding */
    border-radius: 10px;
    margin: 30px 0 30px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

#theKendrickLamarEP .track-section {
    background-color: rgba(80, 80, 63, 0.5);
    color: white;
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

#theKendrickLamarEP .album-cover {
    width: 100%; /* Responsive width */
    max-width: 50px; /* Maintain original size until screen size changes */
    height: auto;
    border: 2px solid #fff;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-right: 10px;
}

#theKendrickLamarEP .album-cover.large {
    max-width: 100px; /* Responsive max-width */
}

#theKendrickLamarEP .album-header,
#theKendrickLamarEP .track-header,
#theKendrickLamarEP .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#theKendrickLamarEP .album-header h2,
#theKendrickLamarEP .track-header h3,
#theKendrickLamarEP .review-header h2 {
    margin: 0;
    font-size: 1em; /* Adjusted font size for mobile */
}

#theKendrickLamarEP .toggle-btn {
    background: none;
    border: none;
    color: #e6e6e6;
    font-size: 1.2em; /* Adjusted for mobile usability */
    cursor: pointer;
    outline: none;
}

#theKendrickLamarEP .title-line {
    width: 100%;
    height: 5px;
    background-color: rgba(23, 23, 15, 0.5);
    margin-bottom: 10px;
    border-radius: 10px;
}

#theKendrickLamarEP .ep-info {
    display: flex;
    flex-direction: column; /* Stack vertically on mobile */
    align-items: flex-start;
    margin-bottom: 20px;
}

#theKendrickLamarEP .ep-image {
    width: 100%; /* Full width on mobile */
    max-width: 300px; /* Maximum width to maintain proportions */
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
}

#theKendrickLamarEP .ep-text {
    max-width: 100%; /* Allows text to fill available space */
    font-size: 1em;
    line-height: 1.6;
}

#theKendrickLamarEP .track-content {
    padding-top: 10px;
}

#theKendrickLamarEP .track-buttons {
    display: flex;
    flex-wrap: wrap; /* Ensure buttons wrap on small screens */
    gap: 5px;
}

#theKendrickLamarEP .track-button {
    padding: 8px 15px; /* Smaller padding for mobile */
    margin-top: 5px;
    background-color: #656553;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em; /* Smaller font size for mobile */
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

#theKendrickLamarEP .track-button:hover {
    background-color: rgba(45, 45, 29, 0.5);
    transform: translateY(-2px);
}

#theKendrickLamarEP .featured-quote {
    margin-top: 10px;
    background-color: rgb(45, 45, 29);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

#theKendrickLamarEP .featured-quote::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(45, 45, 29, 0.5);
    z-index: 1;
}

#theKendrickLamarEP .featured-quote h4 {
    font-size: 1em; /* Smaller font size for mobile */
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
    color: #ffffff;
}

#theKendrickLamarEP .featured-quote p {
    font-size: 0.9em; /* Smaller font size for mobile */
    margin-bottom: 5px;
    line-height: 1.4;
    position: relative;
    z-index: 2;
}

#theKendrickLamarEP .featured-quote span {
    font-size: 0.8em; /* Smaller font size for italics */
    font-style: italic;
    color: #ccc;
    font-weight: normal;
    position: relative;
    z-index: 2;
}

#theKendrickLamarEP .toggle-btn:hover {
    color: #ffffff;
    background-color: rgba(96, 96, 76, 0.5);
}

#theKendrickLamarEP .review-content {
    color: #FFFFFF;
    font-size: 14px; /* Smaller font size for mobile readability */
    line-height: 1.5;
    background-color: rgb(45, 45, 29);
    padding: 20px; /* Reduced padding */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
}

#theKendrickLamarEP .review-summary {
    font-size: 16px; /* Slightly larger font size for emphasis */
    margin-bottom: 10px;
}

#theKendrickLamarEP .review-details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Responsive grid layout */
    gap: 10px;
    margin-bottom: 10px;
}

#theKendrickLamarEP .review-category h3 {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 5px;
}

#theKendrickLamarEP .review-category ul {
    list-style-type: none;
    padding: 0;
}

#theKendrickLamarEP .review-category ul li {
    padding-left: 5px;
    position: relative;
}

#theKendrickLamarEP .review-category ul li::before {
    content: '•';
    color: #ffffff;
    position: absolute;
    left: -5px;
}

#theKendrickLamarEP .review-rating {
    font-size: 18px; /* Reduced font size */
    font-weight: bold;
    display: flex;
    align-items: center;
}

#theKendrickLamarEP .rating {
    font-size: 20px;
    margin-left: 5px;
    color: #eac52d;
}

/* Additional media queries for further responsiveness */
@media (max-width: 768px) {
    /* Adjustments for tablets and below */
    #theKendrickLamarEP .album-header h2, #theKendrickLamarEP .track-header h3, #theKendrickLamarEP .review-header h2 {
        font-size: 1.5em; /* Adjust font size dynamically */
    }
}

@media (max-width: 480px) {
    /* Adjustments for mobile devices */
    #theKendrickLamarEP .ep-section, #theKendrickLamarEP .album-section, #theKendrickLamarEP .track-section, #theKendrickLamarEP .featured-quote, #theKendrickLamarEP .review-section {
        padding: 10px; /* Uniform padding for small devices */
    }

    #theKendrickLamarEP .toggle-btn {
        font-size: 1em; /* Smaller toggle button on mobile */
    }

    #theKendrickLamarEP .track-button, #theKendrickLamarEP .learn-more-btn, #theKendrickLamarEP .lets-start-btn {
        padding: 8px 12px; /* Smaller buttons for easier touch */
    }
}
