/* General resets and box model handling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: 'Helvetica', Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: #1a1a1a;
  height: 100px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.logo-link {
  font-size: 1.8em;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.logo-link:hover {
  color: #6e6e6e;
}

.nav-links {
  display: flex;
  gap: 15px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #6e6e6e;
}

.search-bar {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 1em;
  transition: width 0.3s, border-color 0.3s;
}

.search-bar:focus {
  outline: none;
  border-color: #6e6e6e;
}

button {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  background-color: #444;
  transform: translateY(-2px);
}

button:focus {
  outline: none;
}

main {
  flex: 1;
  padding: 20px;
  margin-top: 50px;
}

footer {
  background-color: #1a1a1a;
  padding: 10px;
  color: white;
  text-align: center;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .search-bar {
    width: 90%; /* Full-width search bar on mobile */
    margin-top: 10px;
    margin-bottom: 10px; /* Additional spacing */
  }

  .logo-link {
    margin-bottom: 10px;
  }

  .App-header {
    height: auto; /* Allow header to expand as needed */
  }
}

.App-header.hide {
  transform: translateY(-100%);
  transition: transform 0.1s ease-in-out;
}
