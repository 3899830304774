/* src/About.css */

.about {
    padding: 40px;
    background-color: #fff;
    color: #333;
    text-align: center;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
}

.about h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.about p {
    font-size: 0.9em;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
}

.additional-info-section {
    position: relative;
    height: 60vh;
    margin-top: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.info-content {
    position: relative;
    z-index: 2;
    color: #000000;
    max-width: 500px;
    text-align: left;
    padding: 20px;
    background: rgb(227, 227, 227);
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    font-size: 0.9em;
}

.info-content h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.info-content p,
.info-content ul {
    margin-bottom: 5px;
}

.info-content ul {
    list-style: none;
    padding: 0;
}

.info-content li {
    margin-bottom: 10px;
}

.dive-right-in-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1em;
}

.dive-right-in-btn:hover {
    background-color: #444;
}

@media (max-width: 768px) {
    .about p, .info-content {
        font-size: 0.8em;
    }

    .additional-info-section, .background-video, .info-content {
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 10px;
    }
}