#OverlyDedicated .ep-section {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(207,207,207,1) 100%);
    color: white;
    padding: 20px;
}

#OverlyDedicated .ep-content {
    max-width: 1000px;
    margin: 0 auto;
}

#OverlyDedicated .album-section {
    background-color: rgb(128, 128, 128);
    color: white;
    padding: 15px;
    margin: 30px 0 30px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

#OverlyDedicated .track-section {
    background-color: rgba(157, 157, 157, 0.5);
    color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    flex: 1 1 300px;
    margin: 20px;
}

#OverlyDedicated .album-cover {
    width: 100%;
    max-width: 50px;
    height: auto;
    border: 2px solid #fff;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-right: 10px;
}

#OverlyDedicated .album-cover.large {
    max-width: 100px;
}

#OverlyDedicated .album-header, .track-header, .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#OverlyDedicated .album-header h2, .track-header h3, .review-header h2 {
    margin: 0;
    font-size: 1em;
}

#OverlyDedicated .toggle-btn {
    background: none;
    border: none;
    color: #e6e6e6;
    font-size: 1.2em;
    cursor: pointer;
    outline: none;
}

#OverlyDedicated .title-line {
    width: 100%;
    height: 5px;
    background-color: rgba(23, 23, 15, 0.5);
    margin-bottom: 10px;
    border-radius: 10px;
}

#OverlyDedicated .ep-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

#OverlyDedicated .ep-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
}

#OverlyDedicated .ep-text {
    max-width: 100%;
    font-size: 1em;
    line-height: 1.6;
}

#OverlyDedicated .track-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

#OverlyDedicated .track-button {
    padding: 8px 15px;
    margin-top: 5px;
    background-color: #383838;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

#OverlyDedicated .track-button:hover {
    background-color: rgba(45, 45, 29, 0.5);
    transform: translateY(-2px);
}

#OverlyDedicated .featured-quote {
    margin-top: 10px;
    background-color: rgb(65, 65, 65);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

#OverlyDedicated .featured-quote::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(115, 115, 115, 0.5);
    z-index: 1;
}

#OverlyDedicated .featured-quote h4, .featured-quote p, .featured-quote span {
    position: relative;
    z-index: 2;
}

#OverlyDedicated .toggle-btn:hover {
    color: #ffffff;
    background-color: rgba(96, 96, 76, 0.5);
}

#OverlyDedicated .review-content {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5;
    background-color: rgba(33, 33, 33, 0.5);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    flex: 1 1 300px;
    margin: 10px;
}

#OverlyDedicated .review-summary {
    font-size: 16px;
    margin-bottom: 10px;
}

#OverlyDedicated .review-details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    margin-bottom: 10px;
}

#OverlyDedicated .review-category h3 {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 5px;
}

#OverlyDedicated .review-category ul {
    list-style-type: none;
    padding: 0;
}

#OverlyDedicated .review-category ul li {
    padding-left: 5px;
    position: relative;
}

#OverlyDedicated .review-category ul li::before {
    content: '•';
    color: #ffffff;
    position: absolute;
    left: -5px;
}

#OverlyDedicated .review-rating {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

#OverlyDedicated .rating {
    font-size: 20px;
    margin-left: 5px;
    color: #eac52d;
}

@media (max-width: 768px) {
    .album-content {
        flex-direction: column;
    }
    .track-section, .review-section {
        flex: 1 1 100%;
    }
}

@media (max-width: 480px) {
    .album-header h2, .track-header h3, .review-header h2 {
        font-size: 1.5em;
    }
    .toggle-btn {
        font-size: 1em;
    }
    .track-button, .learn-more-btn, .lets-start-btn {
        padding: 8px 12px;
    }
}
