.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1a1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none; /* Ensures that when not visible, it doesn't interfere with other UI elements */
}

.loading-screen.visible {
    opacity: 1;
    pointer-events: all; /* Makes the screen interactable when visible */
}

.logo {
    position: relative;
    font-size: 3em; /* Large font size for readability */
    color: transparent;
    -webkit-text-stroke: 1px white;
    display: inline-block;
    overflow: hidden; /* Keeps the fancy text effects contained */
}

.logo h1 {
    font-size: 2em; /* Primary text size */
    color: transparent;
    -webkit-text-stroke: 1px #cacaca;
    display: inline-block;
    overflow: hidden;
    background: linear-gradient(to right, #6e6e6e 50%, transparent 50%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textFillAnimation 1s forwards;
    text-shadow: 0 0 8px rgb(110, 110, 110); /* Adds a subtle glow for better contrast */
}

@keyframes textFillAnimation {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0 0;
    }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .logo h1 {
        font-size: 1.5em; /* Smaller font size for small screens to maintain usability */
    }
}
